import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy } from '@angular/core'
import * as fromShopStore from '@app/shop/store'
import { Subject } from 'rxjs'
import { Store } from '@ngrx/store'
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { ShopShopOrder } from '@app/shop/models/shopOrder.model'

@Component({
  selector: 'app-shop-order-history',
  templateUrl: './shop-order-history.component.html'
})
export class ShopOrderHistoryComponent implements OnInit, OnDestroy {
  destroyed$ = new Subject<boolean>()
  currentShopOrder


  constructor(private store: Store<fromShopStore.State>,) { }

  ngOnInit() {
    this.store.select(fromShopStore.selectShopOrder).pipe(
        takeUntil(this.destroyed$),
        map(order => {
            if(order){
              this.currentShopOrder = order
            }
        })
      ).subscribe()
   }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
