<div class="wrapper">
    <div>
        <table class="table" *ngIf="services?.length; else noServices">
            <tr class="header-row">
                <th class="header-cell">Number</th>
                <th class="header-cell">Type</th>
                <th class="header-cell">Mechanic</th>
                <th class="header-cell">Odometer</th>
                <th class="header-cell">Created At</th>
            </tr>
            @for(service of services; track $index){
                <tr>
                    <td class="header-cell number"><a [routerLink]="['/shop/', service?._id]">{{service.serviceNumber}}</a></td>
                    <td class="header-cell">{{service.serviceType}}</td>
                    <td class="header-cell">{{service?.mechanic?.name}}</td>
                    <td class="header-cell">{{service.odometer || 'N/A'}}</td>
                    <td class="header-cell">{{ service.createdAt | date: 'MM/d/yy' : 'UTC'}}</td>
                </tr>
            }    
        </table>
        <ng-template #noServices>
            <div class="no-services-wrapper">
                <p class="no-services-text">No completed services yet</p>
            </div>
        </ng-template>
    </div>
</div>
