import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import * as fromCoreStore from '@app/core/store'
import * as fromAuth from '@app/users/store'
import * as fromModalStore from '@app/modals/store'
import * as fromShopStore from '@app/shop/store'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { takeUntil, distinctUntilChanged, tap, switchMap } from 'rxjs/operators'
import { CloseModal } from '@app/modals/store/actions/layout.actions'
import { selectAllUsers } from '@app/sidebar/store/selectors/user.selectors'
import { File } from '@app/core/models'
import { environment } from 'environments/environment'
import { FOLLOW_UP_RESOURCE, LockedItem } from '@app/shared/models'
import { LockedItemWorkerFactory, LockItemWorker } from '@app/core/services'
import { getTaskTotalCount } from '@app/shared/store'
import { ShopService } from '@app/shop/models/shopService.model'
import { popupService } from '@app/shared/services/popup.service'

@Component({
    selector: 'app-shop-service-modal',
    templateUrl: './shop-service-modal.component.html',
    styleUrls: ['./shop-service-modal.component.scss']
})
export class ShopServiceModalComponent implements OnInit, OnDestroy {
    @Input()
    props

    destroyed$ = new Subject<boolean>()
    service
    order
    files = []
    fullPaths = []
    filter
    permissions
    isReadOnly = false
    currentUser$
    users$
    categories$: Observable<any>
    taskCount$
    services$: Observable<any>
    serviceTypes$

    lockedItem$: Observable<LockedItem>
    askToUnlockListener$: Observable<number>
    lockedItemWorker: LockItemWorker

    apiUrl = environment.apiUrl
    FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE


    TABS = {
        DETAILS: 'Details',
        HISTORY: 'History'
    }
    selectedTab: string = this.TABS.DETAILS

    constructor(private store: Store<fromModalStore.State>,
        private coreStore: Store<fromCoreStore.State>,
        private cdRef: ChangeDetectorRef,
        private popupService: popupService,
        ) { }

    ngOnInit(): void {
        this.currentUser$ = this.store.select(fromAuth.getUser)
        this.users$ = this.store.select(selectAllUsers)
        this.taskCount$ = this.store.select(getTaskTotalCount)
        this.fullPaths = []
        this.store.dispatch(new fromModalStore.GetFiles({ resource: 'SHOP_SERVICE', resourceId: this.props._id }))
        this.services$ = this.store.select(fromShopStore.selectAllShopServices)
        this.coreStore.select(fromShopStore.selectShopServiceById(this.props._id)).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(service => {
            if (service) {
                this.service = service
                if (service?.mechanic?._id) {
                    this.service.mechanicName = service.mechanic.name
                    this.service.mechanicId = service.mechanic._id
                }
                if (service?.unit?._id) {
                    this.service.unitId = service?.unit?._id
                }
            }
            this.cdRef.detectChanges()
        })
        this.store.select(fromShopStore.selectShopOrderById(this.props._orderId)).pipe(
            takeUntil(this.destroyed$)
        ).subscribe(order=>{
            if(order){
                this.order=order
            }
        })
        this.store.select(fromCoreStore.selectFilesByResource('SHOP_SERVICE', [this.props._id])).pipe(
            takeUntil(this.destroyed$))
            .subscribe(
                (files) => {
                    this.files = files
                    this.store.dispatch(new fromShopStore.ShopServiceUpdateSuccess({ id: this.service._id, changes:{_fileCount:this.files.length}}))
                }
            )
        this.coreStore
            .select(fromAuth.getPermissions)
            .pipe(
                takeUntil(this.destroyed$),
                switchMap(permissions =>
                    this.coreStore.select(fromCoreStore.checkLockedItemLock(this.props._id)).pipe(
                        tap(lock => {
                            this.permissions = permissions
                            this.isReadOnly = !permissions?.['shop_update']
                        })
                    )

                )
            )
            .subscribe()

        this.serviceTypes$ = this.store.select(fromShopStore.selectShopServicesTypes)   
    }

    updateShopService(data) {
        this.coreStore.dispatch(
            new fromShopStore.ShopServiceUpdate({
                service: { id: data._id, _orderId: this.props._orderId, changes: data },
                closeModal: false
            })
        )
    }
    save(data) {
        if (data._id) {
            this.coreStore.dispatch(
                new fromShopStore.ShopServiceUpdate(data)
            )

            this.close()
        }
    }
    onDelete = async (data) => {
        if (await this.popupService.confirm(`Are you sure you want to delete?`)) {
            this.store.dispatch(new fromShopStore.DeleteShopService({ _orderId: this.props._orderId, ...data }))
            this.close()
        }
    }

    close() {
        this.store.dispatch(new CloseModal())
    }

    deletePhoto() {
        if(this.files.length===1){
            this.store.dispatch(new fromShopStore.ShopServiceUpdateSuccess({ id: this.service._id, changes:{_hasPhoto:false}}))
        }
    }


    askToUnlock = () => (this.askToUnlockListener$ = this.lockedItemWorker.askToUnlock())
    updateLock = () => this.lockedItemWorker.updateLock()

    ngOnDestroy(): void {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}
