import { Component, OnDestroy, OnInit, HostListener, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'
import * as fromShopStore from '@app/shop/store'
import { selectedOrder } from '@app/core/store/selectors/order.selectors'
import { selectStopById } from '@app/core/store/selectors/stop.selectors'
import { OpenModal } from '@app/modals/store'
import { Stop, Order, Inspection, Contact } from '@app/shared/models'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { map, takeUntil, tap } from 'rxjs/operators'
import { User } from '@app/shared/models/user.model'
import { Console } from 'console'

@Component({
  selector: 'app-shop-completed-shop-service',
  templateUrl: './completed-shop-service.component.html',
  styleUrls: ['./completed-shop-service.component.scss']
})
export class ShopCompletedShopServicesComponent implements OnInit, OnDestroy {
  @Input()
  currentUser

  @Input()
  set filterSetter(v) {
    this.filters = {
      page: 1,
      reverseCriteria: -1,
      sortCriteria: 'serviceNumber',
      status: 'COMPLETED',
      unitId: v,
      limit: 100
    }
    this.store.dispatch(new fromShopStore.GetCompletedShopServices(this.filters))
    this.unitId = v
    this.store
      .select(fromShopStore.selectCompletedShopServices(this.unitId))
      .pipe(
        takeUntil(this.destroyed$),
        map(services => {
          this.services = services
          return services
        })
      )
      .subscribe()
  }
  unitId

  destroyed$ = new Subject<boolean>()
  services$: Observable<any>
  services
  selectedShopServices = []
  showModal = false
  servicesTotal: Number
  filters: {
    page: Number
    limit: Number
    unitId?: String
    unitName?: String
    mechanicName?: String
    serviceType?: String
    status?: String
    sortCriteria?: String
    reverseCriteria?: Number
  }

  constructor(
    private store: Store<fromShopStore.State>,
    private autStore: Store<fromUserStore.State>,
    private coreStore: Store<fromCoreStore.State>
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
