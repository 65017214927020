import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

import { Store, select } from '@ngrx/store'

import { Observable, of } from 'rxjs'
import { map, take, switchMap, catchError } from 'rxjs/operators'

import * as fromShopStore from '@app/shop/store'

@Injectable()
export class ShopServiceExistsGuards {
  constructor(private shopStore: Store<fromShopStore.State>) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasOrder(route.params.orderId || route.parent?.params?.orderId,).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    )
  }

  hasOrderInApi(_id: string): Observable<boolean> {
    return this.shopStore.pipe(
      select(fromShopStore.selectAllShopOrders),
      map(order => {
        this.shopStore.dispatch(
          new fromShopStore.GetOneShopOrder({ _id: _id, customer: true, driverDetails: true, shopDetails: true, unitDetails: true, samsaraOdometer: true })
        )
        this.shopStore.dispatch(
          new fromShopStore.GetShopOrderServices({ _orderId: _id, limit: 100 })
        )
        this.shopStore.dispatch(
          new fromShopStore.GetServiceTypes({})
        )
        return !!order
      }),
      take(1)
    )
  }

  hasOrder(_id: string): Observable<boolean> {
    return this.hasOrderInApi(_id)
  }
}
