<div class="show-modal-header">
    <button class="ml-auto" type="button" (click)="close()" aria-label="Close">
      <i class="fas fa-times text-gray-900"></i>
    </button>
</div>
<div class="show-modal-body">
    <div>
      <form class="flex justify-between">
        <div [style.display]="selectedTab===TABS.DETAILS?'block':'none'" class="shop-images-wr">
          <p class="no-files-test" *ngIf="!files?.length">No files Selected</p>
          <app-multi-file-viewer [files]="files" (onDelete)="deletePhoto()"></app-multi-file-viewer>
        </div>
        <div class="shop-service-details">
          <app-files
            *ngIf="service?._id"
            [title]="'Service Files'"
            [resource]="'SHOP_SERVICE'"
            [resourceId]="service?._id"
          ></app-files>
        </div>  
      </form>
    </div>
  </div>
<div class="show-modal-footer">
  <button class="ml-auto close-btn" type="button" (click)="close()">
    Close
  </button>
</div>
  
  