import { Component, OnDestroy, OnInit, HostListener } from '@angular/core'
import * as fromCoreStore from '@app/core/store'
import * as fromUserStore from '@app/users/store'
import * as fromShopStore from '@app/shop/store'
import * as fromSidebarStore from '@app/sidebar/store'
import { Store } from '@ngrx/store'
import { Observable, Subject } from 'rxjs'
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { FOLLOW_UP_RESOURCE, Note, LockedItem } from '@app/shared'
import { ShopShopOrder } from '@app/shop/models/shopOrder.model'
import { LockedItemWorkerFactory, LockItemWorker } from "@app/core/services"

@Component({
  selector: 'app-shop-order-router',
  template: `
        <div class="container-shop-order" style="background: white">
          <app-locked-item
            *ngIf="shopOrder$ | async as order"
            [type]="FOLLOW_UP_RESOURCE.SHOP_ORDER"
            [itemName]="'#'+order?.orderNumber"
            [lockedItem]="lockedItem$ | async"
            [askToUnlockTimer]="askToUnlockListener$ | async"
            (askToUnlock)="askToUnlock()"
            (updateLock)="updateLock()"
          ></app-locked-item>
          <router-outlet></router-outlet>
        </div>
  `,
  styles: [`
    .container-shop-order {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 10px
    }
  `]
})
export class ShopOrderItemRouterComponent implements OnInit, OnDestroy {
  lockedItem$: Observable<LockedItem>
  askToUnlockListener$: Observable<number>
  lockedItemWorker: LockItemWorker
  locked$: Observable<boolean>

  shopOrder$: Observable<ShopShopOrder>

  FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE

  constructor(
    private store: Store<fromShopStore.State>,
    private lockedItemWorkerFactory: LockedItemWorkerFactory,
  ) { }

  ngOnInit(): void {
    this.shopOrder$ = this.store.select(fromShopStore.selectShopOrder)
    this.lockedItem$ = this.store.select(fromShopStore.selectCurrentShopOrderId).pipe(
      distinctUntilChanged(),
      tap(
        id =>
        (this.lockedItemWorker = this.lockedItemWorkerFactory.createLockItemWorker(
          id,
          FOLLOW_UP_RESOURCE.SHOP_ORDER,
          fromCoreStore,
          { changeWindowTitle: true }
        ))
      ),
      switchMap(() => this.lockedItemWorker.listenAndLock())
    )
    this.locked$ = this.store.select(fromShopStore.selectCurrentShopOrderId).pipe(
      switchMap(id => this.store.select(fromCoreStore.checkLockedItemLock(id))
        .pipe(
          map(lock => !lock.lockedByCurrentTab)
        )
      )
    )
  }

  askToUnlock = () => (this.askToUnlockListener$ = this.lockedItemWorker.askToUnlock())
  updateLock = () => this.lockedItemWorker.updateLock()

  ngOnDestroy(): void {
    this.store.dispatch(new fromCoreStore.ClearItemReducer())
  }
}
