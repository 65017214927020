<div class="shop-list-wrapper font-poppins" id="scrollIntoTop">
  <div class="filters-wrapper">
    <app-shop-filter (onFilterChange)="updateFilter($event)" [filterSetter]="filters" [loading]="loading" [serviceTypes]="serviceTypes" [total]="ordersTotal" [counts]="counts" [stats]="stats" [_currentUser]="this.currentUser">
    </app-shop-filter>
  </div>
  <div [hidden]="!selectedShopOrders?.length" class="flex localSelectedOrders-wr justify-between w-full items-center mt-2 mb-3 p-3 bg-white rounded-xl relative">
    <div #localSelectedOrders class="localSelectedOrders overflow-hidden">
      <div #localSelectedOrdersItems class="localSelectedOrders-items">
        @for(order of selectedShopOrders; track $index){
          <div class="flex items-center selected-item flex" [ngClass]="{
            'bg-red-100 text-red-900': order.status.toLocaleLowerCase() === 'new',
            'bg-green-100 text-green-900': order.status.toLocaleLowerCase() === 'active',
            'on-hold-order': order.status.toLocaleLowerCase() === 'on_hold',
            'closed-order': order.status.toLocaleLowerCase() === 'closed',
            'bg-blue-100 text-blue-900': order.status.toLocaleLowerCase() === 'completed',
          }">
            <span class="text-base font-semibold">{{order.orderNumber}}</span>
            <button (click)="onDeselectOrder(order)"><i class="fas fa-times" style="margin-left: 7px;" [ngClass]="{
              'text-red-900': order.status.toLocaleLowerCase() === 'new',
              ' text-green-900': order.status.toLocaleLowerCase() === 'active',
              'on-hold-order': order.status.toLocaleLowerCase() === 'on_hold',
              'closed-order': order.status.toLocaleLowerCase() === 'closed',
              'text-blue-900': order.status.toLocaleLowerCase() === 'completed',
            }"></i></button>
          </div>
        }
      </div>
    </div>
    <div class="selected-actions">
      <button class="restore" (click)="onRestoreSelectedOrders()">Restore</button>
      <button class="deselect" (click)="selectedShopOrders = []" style="white-space: nowrap;">De-Select</button>
    </div>
    <button *ngIf="localSelectedOrdersItems.offsetHeight > 71" (click)="toggleHeight()" class="absolute toggleHeight px-2 pb-0.5 pt-1 rounded-full bg-white">
      <i class="fa-solid" [ngClass]="[localSelectedOrders.offsetHeight == 60 ? 'fa-chevron-down' : 'fa-chevron-up']" style="font-size: 13px;"></i>
    </button>
  </div>
  <!-- appSelectPop.offsetWidth -->
  @defer (when !loading) {
    <div
      *ngIf="{
        orders: orders$ | async
      } as observables"
    >
      <ngx-datatable
        class="custom-datatable"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        [rowHeight]="'auto'"
        [rows]="orders"
        [scrollbarH]="false"
        [selectionType]="selectionType.checkbox"
        [limit]="filters?.limit"
        (sort)="onSort($event)"
        [sorts]="[{ prop: 'orderNumberProp', dir: 'desc' }]"
      >
        <ngx-datatable-column *ngIf="filters?.recovery" name="SELECT" [width]="15">
          <ng-template let-column="column" ngx-datatable-header-template>
            <input class="custom-checkbox w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" [checked]="selectedShopOrders?.length > 0 && selectedShopOrders?.length == orders?.length" type="checkbox" placeholder="Enter Text" (click)="selectedShopOrders?.length == orders?.length ? selectedShopOrders = [] : selectedShopOrders = orders">
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span *ngIf="row?._locked">
              <i class="fas fa-lock" title="This trip locked by {{ row._locked.account.name }}"> </i>
            </span>
            <input *ngIf="!row?._locked" class="custom-checkbox w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded" type="checkbox" placeholder="Enter Text" [checked]="isChecked(row._id)"
            (click)="selectShopOrder(row, $event)">
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Shop #" prop="orderNumberProp" [sortable]="true" [width]="50">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span>{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>
                <a
                  *ngIf="!row?.deleted"
                  href="#"
                  routerLinkActive="router-link-active"
                  class="d-inline-block position-relative"
                  [routerLink]="[row?._id]"
                >
                  <span class="order-number-text">{{ row.orderNumber }}</span>
                </a>
                <span *ngIf="row?.deleted" class="order-number-text">{{ row.orderNumber }}</span>
            </div>
            <span title="RefNumber" class="badge badge-sm badge-outline badge-dark" *ngIf="row.refNumber" style="max-width: 100%; text-wrap: balance;">{{row.refNumber}}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Status" prop="status" [sortable]="false" [width]="66">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span>{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div>
              <span
                *ngIf="row.status"
                class="order_status font-medium"
                [ngClass]="{
                  'bg-red-100 text-red-900': row.status.toLocaleLowerCase() === 'new',
                  'bg-green-100 text-green-900': row.status.toLocaleLowerCase() === 'active',
                  'on-hold-order': row.status.toLocaleLowerCase() === 'on_hold',
                  'closed-order': row.status.toLocaleLowerCase() === 'closed',
                  'bg-blue-100 text-blue-900': row.status.toLocaleLowerCase() === 'completed',
                }"
                >{{ row.status }}</span
              >
            </div>
            <!-- <p title="Invoice Number" class="invoice_number" *ngIf="row?.accounting?.invoiceNumber">{{row?._invoiceNumber}}</p> -->
            @if (row?._invoiceNumber && row?._invoiceNumber?.length) {
              <div class="mt-1" style="display: flex; flex-wrap: wrap; column-gap: 3px; row-gap: 2px;">
                @for(invoiceNumber of row?._invoiceNumber; track $index){
                  <span title="Invoice Number" class="invoice_number">{{invoiceNumber && invoiceNumber !== 'undefined' ? (invoiceNumber || '') : ''}}</span>
                }
              </div>
            }
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Arrival" prop="byaArrivalDate" [width]="50" [sortable]="true">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span>{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="col p-0">
              <div *ngIf="row?.arrivalDate">
                <span class="default-date" [ngClass]="[row.status === 'NEW' ? getDaysClass('a',row.arrivalDate) : 'default-arrival']" title="Arrival Date">A</span>
                <small class="default-date-text">{{row.arrivalDate | date: 'MM/dd': 'UTC'}}</small>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Departure" prop="byDeparture" [width]="80" [sortable]="true">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span>{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="col p-0">
              <div *ngIf="row?.departureDate" class="">
                <span class="default-date" [ngClass]="[row.status === 'ACTIVE' ? getDaysClass('a',row.departureDate) : 'default-arrival']" title="Departure Date">D</span>
                <small class="default-date-text">{{row.departureDate | date: 'MM/dd': 'UTC'}}</small>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Unit" prop="unitProp" [width]="20"  [sortable]="true">
          <ng-template let-row="row" ngx-datatable-cell-template>
            @for(unit of row._units; track $index){
              <div>
                <i *ngIf="unit?._isBlocked" [title]="'This contact locked by ' + unit?._isBlocked?.account?.name" class="fa-solid fa-lock lock-item-icon" style="margin-right: 5px; color: black;"></i>
                <a class="unit-item" href="javascript:void(0);" (click)="openModal({_id: unit?._id, carrierId:currentUser?.company?._id, selectResource: 'SET_EQUIPMENT'}, 'EQUIPMENT_FORM')">{{unit?.details}}</a>
              </div>
            }

          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Year/Make/Model" prop="unitYear" [width]="240"  [sortable]="false">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span>{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="grow">
                <div class="flex items-center">
                  <div>
                    @for(unit of row._units; track $index){
                      <div>
                        <span *ngIf="unit?.equipmentType === 'TRUCK' && unit?.haulType" [title]="unit.haulType" [ngClass]="[unit.haulType === 'LOCAL'?'local-haulType':'no-local-haulType']" class="default-haulType">
                          {{unit.haulType === 'LOCAL'? 'L' : 'H'}}
                        </span>
                      </div>
                    }

                  </div>
                  <div class="unit-items">
                    @for(unit of row._units; track unit._id){
                      <p class="unit-item-car">
                        {{unit?.year}} {{unit?.make}} {{unit?.model}}<i class="fa-solid fa-truck" [ngClass]="{
                          'fa-truck': unit?.equipmentType == 'TRUCK',
                          'fa-trailer': unit?.equipmentType == 'TRAILER',
                        }"></i>
                      </p>
                    }
                  </div>
                </div>
              </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Driver" prop="_driver" [sortable]="true" >
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span>{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div *ngIf="row?._driver" class="driver-name">
              <a *ngIf="row?._driver?._id" href="javascript:void(0);" (click)="openModal({contactId: row?._driver?._id, size: 'md', windowClass : 'contact-modal'}, 'UPDATE_CONTACT')">{{ row?._driver?.name }}</a>
              <!-- <a *ngIf="row?._driver?.address" class="" (click)="openMap(row?._driver?.address)">
                <button *ngIf="row?._driver?.address" class="1" title="Show Driver Location" (click)="openMap(row?._driver?.address)"><i class="fa-solid fa-location-dot"></i></button>{{row?._driver?.address?.city+','||''}} {{row?._driver?.address?.state||''}}
              </a> -->
              <button *ngIf="row?._driver?.location" (click)="openMap(row?._driver?.location)" class="ml-2 driver-location" title="Show Driver Location" ><i class="fa-solid fa-location-dot"></i></button>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Services" prop="_serviceTypeCounts" [sortable]="true" [width]="70">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span>{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div *ngIf="row?.odometer"><i class="fa-solid fa-gauge-high text-gray-500 service-text-icons"></i><span class="service-text">{{row?.odometer | number}}</span></div>
            <div *ngIf="row?._serviceTypeCounts && row?._serviceTypeCounts?.LABOR + row._serviceTypeCounts?.PART > 0">
              <span class="service-text service-types">
                <!-- <span class="service-types-total mr-2">{{row._serviceTypeCounts?.TOTAL}}</span> -->
                <span title="Labor">{{row._serviceTypeCounts?.LABOR}}
                  <i class="fa-solid fa-hand text-gray-500 ml-1 mr-2"></i></span>
                <span title="Part">{{row._serviceTypeCounts?.PART}}
                  <i class="fa-solid fa-gears text-gray-500 ml-1"></i></span>
              </span>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Total" prop="_totalAmount" [sortable]="true" [width]="50">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span>{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <span class="total-text">
              $ {{ row?._totalAmount | myCurrency }}
            </span>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Shop" prop="description" [width]="110" [sortable]="false">
          <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span>{{ column.name }}</span>
          </ng-template>
          <ng-template let-row="row" ngx-datatable-cell-template>
            <div class="col p-0">
              <div>
                <a class="shop-name" *ngIf="row?.shop?._id" href="javascript:void(0);" (click)="openModal({contactId: row?.shop?._id}, 'EditContact')">{{ row?.shop?.name }}</a>
              </div>
              <div class="mt-1">
                <a class="shop-name" *ngIf="row?.mechanic?._id" href="javascript:void(0);" (click)="openModal({contactId: row?.mechanic?._id, size: 'md',windowClass: 'contact-modal'}, 'UPDATE_CONTACT')">{{ row?.mechanic?.name }}</a>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-footer>
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-curPage="curPage"
            let-offset="offset"
            let-isVisible="isVisible"
          >
            <div class="pagination-cantainer pb-2">
              <app-pagination
                (pageChange)="onPageChange($event)"
                [options]="{white: true}"
                [total]="ordersTotal"
                [limit]="filters.limit"
                [currentPage]="filters?.page"
              ></app-pagination>
            </div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  }@placeholder{
    <div>
      <app-preloader [type]="'CONTENT_LIST'" [count]="8" [customHeight]="'70px'"></app-preloader>
    </div>
  }
</div>
