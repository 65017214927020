import { ChangeDetectorRef, Component, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core'
import * as fromUserStore from '@app/users/store'
import * as fromShopStore from '@app/shop/store'
import { Store, select } from '@ngrx/store'
import { Observable, Subject, combineLatest } from 'rxjs'
import { map, takeUntil, tap } from 'rxjs/operators'
import { User } from '@sentry/browser'
import { SelectionType } from '@swimlane/ngx-datatable'
import { OpenModal } from '@app/modals/store'
import { diffBetweenDates } from '@app/shared/utils/timeFunctions.utils'
import { environment } from 'environments/environment'
import { popupService } from '@app/shared/services/popup.service'
import { ActivatedRoute, Router } from '@angular/router'
import * as fromCoreStore from '@app/core/store'
import * as fromModalStore from '@app/modals/store';
const API_KEY = environment.google.mapKey
@Component({
  selector: 'app-shop-order-list',
  templateUrl: './shop-order-list.component.html',
  styleUrls: ['./shop-order-list.component.css']
})
export class ShopLoadboardComponent implements OnInit, OnDestroy {
  @ViewChild('localSelectedOrders') localSelectedOrders: ElementRef;
  destroyed$ = new Subject<boolean>()
  isSelectedAll = false
  isDisableCheckboxes = true
  orders$: Observable<any>
  currentUser: User
  orders
  selectedShopOrders = []
  showModal = false
  ordersTotal: number
  filters: any = {
    page: 1,
    reverseCriteria: -1,
    sortCriteria: 'orderNumber',
    limit: 25,
    driverDetails: true,
    driverLocation: true
  }
  selectionType = SelectionType
  counts
  serviceTypes
  stats
  fromStore: typeof fromCoreStore | typeof fromModalStore = fromCoreStore
  loading

  constructor(
    private store: Store<fromShopStore.State>,
    private autStore: Store<fromUserStore.State>,
    private popupService: popupService,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) { }
  ngOnInit(): void {
    // empty current Order
    this.store.dispatch(new fromShopStore.GetOneShopOrderSuccess({}))
    
    this.store.dispatch(
      new fromShopStore.GetServiceTypes({})
    )

        this.filters.page = 1


    this.store.dispatch(new fromShopStore.CleanShopServices())
    this.autStore
      .select(fromUserStore.getUser)
      .pipe(
        takeUntil(this.destroyed$),
        map(user => {
          this.currentUser = user
        })
      )
      .subscribe()

    this.store
      .pipe(
        select(fromShopStore.selectShopsTitleAndCounts),
        takeUntil(this.destroyed$),
        map((counts) => {
          this.counts = counts
        })
      )
      .subscribe()

      combineLatest([
        this.store.select(fromCoreStore.selectLockedItemsForCurrentUser),
        this.store.pipe(select(fromShopStore.selectAllShopOrders) )
      ]).pipe(
        takeUntil(this.destroyed$),
        tap(([lockedItems, orders]) => {
          let localOrders: any = [...orders] || []
          if (orders || lockedItems) {
            let units = orders.flatMap(order => order?._units);
            let equpmentsForLocks = units.map(eqt => ({
              _id: eqt?._id,
            }));
            this.store.dispatch(new this.fromStore.SetEquipmentsForLock(equpmentsForLocks));
            
            let lockedIds = lockedItems.map(l => l?.id)
            for (let order of localOrders) {
              if (order?._units) {
                for (let unit of order?._units) {
                  for (let lockItem of lockedItems) {
                    if (unit?._id == lockItem?.id) {
                      unit._isBlocked = lockItem
                    } 
                  }
                  if (!lockedIds.includes(unit?._id)) {
                    unit._isBlocked = null;
                  }
                }
              }
            }

            for (let i = 0; i < localOrders.length; i++) {
              let order = localOrders[i];
              if (order?.accounting?.invoiceNumber && order?.accounting?.invoiceNumber?.length) {
                localOrders[i] = {
                  ...order,
                  _invoiceNumber: order?.accounting?.invoiceNumber && !order?.accounting?.invoiceNumber.includes('undefined') ? order?.accounting?.invoiceNumber.split(',') : ''
                };
              }
            }
          }
          this.orders = localOrders
          this.cdr.detectChanges();
        })
      ).subscribe();

    this.store
      .select(fromShopStore.selectShopOrdersTotal)
      .pipe(
        takeUntil(this.destroyed$),
        map(ordersCount => {
          this.ordersTotal = ordersCount
          return ordersCount
        })
      )
      .subscribe()
    this.store
      .select(fromShopStore.selectShopOrdersStats)
      .pipe(
        takeUntil(this.destroyed$),
        map(stats => {
          this.stats = stats
          return stats
        })
      )
      .subscribe()
    this.store
      .select(fromShopStore.selectShopServicesTypes)
      .pipe(
        takeUntil(this.destroyed$),
        map(types => {
          this.serviceTypes = types
          return types
        })
      )
      .subscribe()

    this.store
      .select(fromShopStore.selectShopOrdersLoading)
      .pipe(
        takeUntil(this.destroyed$),
        map(loading => {
          this.loading = loading
          this.cdr.detectChanges()
          return loading
        })
      )
      .subscribe()

    this.store.select(fromShopStore.selectAllFiltersWithUserCompanyShop)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(filter => {
        for (const property in filter) {
          if (
            filter[property] === null ||
            ((Array.isArray(filter[property]) || typeof filter[property] === 'string') &&
              !filter[property]?.length)
          ) {
            delete filter[property]
          }
        }
        this.filters = filter
        const data = { ...filter }
        delete data.unitName
        delete data.shopName
        delete data.mechanicName
        delete data.driverName
        delete data.customerName
        delete data.units

        if (!filter?.authentication) {
          this.store.dispatch(new fromShopStore.GetShopOrders(data))
        }

      })
  }

  toggleHeight() {
    const element = this.localSelectedOrders.nativeElement;
    const currentHeight = element.style.height;
    element.style.height = currentHeight === 'auto' ? '60px' : 'auto';
  }

  selectAllShopOrders(event) {
    for (let order of this.orders) {
      if (!order._selected && event.target.checked) {
        this.selectedShopOrders.push(order)
      }
      else if (event.target.checked == false) {
        this.selectedShopOrders = []
      }
      order._selected = event.target.checked
    }
  }

  selectShopOrder(order, event) {
    order._selected = event.target.checked
    event.target.checked
      ? this.selectedShopOrders.push(order)
      : (this.selectedShopOrders = this.selectedShopOrders.filter(e => e._id != order._id))
  }

  updateFilter(filter) {
    this.selectedShopOrders = []
    let filtersLocal = {
      ...this.filters,
      ...filter,
      page: 1
    }
    this.store.dispatch(new fromShopStore.UpdateFilter(filtersLocal))
  }

  onPageChange(event) {
    this.filters.page = event
    this.store.dispatch(new fromShopStore.UpdateFilter(this.filters))
  }

  onDeselectOrder(order) {
    this.selectedShopOrders = this.selectedShopOrders.filter(o => o !== order);
  }

  openModal = (props, type) => {
    this.store.dispatch(
      new OpenModal({
        type,
        props
      })
    )
  }

  onSort(e) {
    //clear all previous sortings
    delete this.filters.sortCriteria
    //set order of sorting
    if (e.sorts[0].dir == 'asc') {
      this.filters.reverseCriteria = 1
    }
    else {
      this.filters.reverseCriteria = -1
    }

    // if (this.filters?.customerName) {
    //   delete this.filters.customerName
    // }
    this.filters = {
      ...this.filters,
      page: 1
    }

    //use switch to check what field is sorted
    switch (e.sorts[0].prop) {

      case 'orderNumberProp':
        this.filters.sortCriteria = 'orderNumber'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break

      case 'unitProp':
        this.filters.sortCriteria = '_units.details'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break

      case 'mechanicProp':
        this.filters.sortCriteria = 'mechanic.name'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break

      case 'orderTypeProp':
        this.filters.sortCriteria = 'orderType'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break
      case '_totalAmount':
        this.filters.sortCriteria = '_totalAmount'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break
      case '_driver':
        this.filters.sortCriteria = '_driver.name'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break
      case '_serviceTypeCounts':
        this.filters.sortCriteria = '_serviceTypeCounts.TOTAL'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break
      case 'unitYear':
        this.filters.sortCriteria = 'unitYear'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break
      case 'byaArrivalDate':
        this.filters.sortCriteria = 'arrivalDate'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break
      case 'byDeparture':
        this.filters.sortCriteria = 'departureDate'
        this.store.dispatch(new fromShopStore.GetShopOrders(this.filters))
        break
    }
    
  }

  getDaysClass(type, date) {
    const days = diffBetweenDates(date)
    if (days === 1) {
      return 'badge-warning'
    }
    if (days === 0) {
      return 'badge-success'
    }
    if (days < 0) {
      return 'badge-danger'
    }
    if (days > 0) {
      return 'badge-outline badge-dark'
    }
    return 'badge-outline badge-dark'
  }


  isChecked = orderId => this.selectedShopOrders.find(t => t._id === orderId);

  async onRestoreSelectedOrders() {
    if (await this.popupService.confirm('Are you sure you want to restore selected work-orders?')) {
      this.store.dispatch(new fromShopStore.RestoreManyShopOrders({ ids: this.selectedShopOrders.map(t => t._id) }))
      this.selectedShopOrders = []
      const data = { ...this.filters }
      delete data.unitName
      delete data.shopName
      delete data.mechanicName
      delete data.driverName
      delete data.customerName
      this.store.dispatch(new fromShopStore.GetShopOrders(data))
    }
  }


  openMap(location) {
    this.store.dispatch(
      new OpenModal({
        type: 'MAP',
        props: {
          action: 'POINT',
          location: { lat: location[0][0], lng: location[0][1] }
        },
      })
    )
  }

  ngOnDestroy(): void {
    this.store.dispatch(new this.fromStore.SetEquipmentsForLock([]))
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}

